<template>
  <div>
    <!-- Tab Mobile View Header -->
    <header class="tabMobileView header navbar fixed-top d-lg-none">
      <div class="nav-toggle">
        <a href="javascript:void(0);" class="nav-link sidebarCollapse" data-placement="bottom">
          <i class="flaticon-menu-line-2"></i>
        </a>
        <a href="index.html" class="">
          <img :src="baseUrl + 'assets/images/logo.png'" class="img-fluid" alt="logo" />
        </a>
      </div>
      <ul class="nav navbar-nav">
        <li class="nav-item dropdown user-profile-dropdown ml-lg-0 mr-lg-2 ml-0 order-lg-0 order-1 d-lg-none">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="flaticon-user-12"></i>
          </a>
          <div class="dropdown-menu  position-absolute" aria-labelledby="userProfileDropdown">
            <a class="dropdown-item" href="user_profile.html">
              <i class="mr-1 flaticon-user-6"></i> <span>My Profile</span>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click.prevent="logout" href="#">
              <i data-feather="power" class="mr-1 flaticon-power-button"></i> Logout
            </a>
          </div>
        </li>
      </ul>
    </header>
    <!-- Tab Mobile View Header -->

    <!--  BEGIN NAVBAR  -->
    <header class="header navbar fixed-top navbar-expand-sm d-flex justify-content-end d-none">
      <a href="javascript:void(0);" class="sidebarCollapse d-none d-lg-block" data-placement="bottom"><i class="flaticon-menu-line-2"></i></a>

      <ul class="navbar-nav flex-row mr-lg-auto ml-lg-0  ml-auto">
        <notification-component />
      </ul>

      <ul class="navbar-nav flex-row ml-lg-auto">

        <li class="nav-item dropdown user-profile-dropdown ml-lg-0 mr-lg-2 ml-0 order-lg-0 order-1" v-if="user">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="d-flex">
              <div class="mr-2" style="line-height: 15px !important;">
                <span class="fs-15">{{ user.name }}<br>
                  <span class="fs-10">Admin</span>
                </span>
              </div>
              <div class="ms-auto bd-highlight">
                <i class="flaticon-user-6"></i>
              </div>
            </div>
          </a>
          <div class="dropdown-menu  position-absolute"
               v-if="user"
               aria-labelledby="userProfileDropdown">
            <a class="dropdown-item" @click.prevent="logout" href="#">
              <i data-feather="power" class="mr-1 flaticon-power-button"></i> Logout
            </a>
          </div>
        </li>
      </ul>
    </header>
    <!--  END NAVBAR  -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import NotificationComponent from './NotificationComponent.vue'

export default {
  components: {NotificationComponent},
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
        .finally(() => {
          this.$router.push({name: 'login'})
        })
    }
  }
}
</script>
import auth from "../middlewares/auth";
export const userRoutes = [
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "users" */ '../views/user/List.vue'),
    name: 'users',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/users/:id',
    component: () => import(/* webpackChunkName: "users" */ '../views/user/Details.vue'),
    name: 'users_details',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];
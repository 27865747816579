import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";
import { userRoutes } from "@/routes/userRoutes";
import { agencyRoutes } from "@/routes/agencyRoutes";

let routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    name: 'dashboard',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/notifications',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/notification/NotificationList.vue'),
    name: 'notifications',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/logs',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/log/List.vue'),
    name: 'logs',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/version',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/version/Index.vue'),
    name: 'version',
    meta: {
      middleware: [
        auth
      ]
    }
  },
]

routes.push(...userRoutes);
routes.push(...agencyRoutes);

export default routes;

import auth from "../middlewares/auth";
export const agencyRoutes = [
  {
    path: '/agency',
    component: () => import(/* webpackChunkName: "agency" */ '../views/agency/List.vue'),
    name: 'agency',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];
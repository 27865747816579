<template>
  <li class="nav-item dropdown notification-dropdown ml-3">
    <a href="#"
       @click.prevent="readNotification"
       class="nav-link dropdown-toggle"
       id="notificationDropdown"
       data-toggle="dropdown"
       aria-haspopup="true"
       aria-expanded="false">
      <span class="flaticon-bell-4"></span>
      <span class="badge badge-success" v-if="unread">{{ unread }}</span>
    </a>
    <div class="dropdown-menu position-absolute" aria-labelledby="notificationDropdown">
      <a class="dropdown-item title" href="javascript:void(0);" v-if="unread">
        <i class="flaticon-bell-13 mr-3"></i> <span>You have {{ unread }} new notifications</span>
      </a>

      <notification-item v-for="(notification, i) in notifications"
                         :notification="notification"
                         :key="'notification_'+i" />

      <router-link :to="{name: 'notifications'}"
                   class="footer dropdown-item text-center p-2">
        <span class="mr-1">View All</span>
        <div class="btn btn-gradient-warning rounded-circle"><i class="flaticon-arrow-right flaticon-circle-p"></i></div>
      </router-link>
    </div>
  </li>
</template>

<script>
import NotificationItem from './NotificationItem.vue'
export default {
  components: {NotificationItem},
  data() {
    return {
      notifications: [],
      unread: 0,
    }
  },
  created() {
    this.axios.get('/notifications?take=5')
      .then(res => {
        this.unread = res.data.unread;
        this.notifications = res.data.data;
      })
  },
  methods: {
    readNotification() {
      this.axios.post('/notifications/read')
    }
  }
}
</script>
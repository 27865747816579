<template>
  <a :class="['dropdown-item text-center p-1', {'unread': !notification.read}]"
     href="javascript:void(0);">
    <div class="notification-list ">
      <div class="notification-item position-relative" style="padding: 5px 0 5px 0;">
        <p class="text-black mb-1">{{ generateText() }}</p>
        <p><span class="meta-time">{{ notification.created_at }}</span></p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: ['notification'],
  methods: {
    generateText() {
      if (this.notification.event === 'new_user') {
        return "New account created by " + this.notification.email;
      } else if (this.notification.event === 'new_subscription') {
        return this.notification.subscribed_by.email + " subscribed " + this.notification.plan.name;
      } else if (this.notification.event === 'license_activation') {
        return this.notification.activated_by.email + " activated " + this.notification.agency + " license";
      } else if (this.notification.event === 'subscription_cancel') {
        return this.notification.user.email + " cancel subscription";
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.unread {
    background-color: lightslategray;
}
</style>